import { Heading, IconExclamation, ToolTip } from '@madpaws/design-system';

import {
  SFS_HOME_SEARCH_TEST_EPPO_ID,
  SFS_HOME_SEARCH_TEST_EPPO_ID_VALUES,
} from '~/components/Eppo/constants';
import { useEppoStringAssignment } from '~/components/Eppo/helpers';
import { SERVICE_OPTIONS, SERVICE_OPTIONS_V2 } from '~/components/constants';

import styles from './AdditionalRatesDetails.module.css';
import { getServicesWithAdditionalRates } from './utils';
import { findServiceById } from '../../utils';

import type { RateDetail } from './types';
import type { ReactElement } from 'react';
import type { SitterProfileService } from '~/common/types/sitter';

export type RateDetailsProps = {
  sitterProfileServices: SitterProfileService[];
};

// Usually we use "renderSomeItem" helper function for that purpose,
// but I found using component approach is more convenient here
// didn't move it to another file because of the same styles usage
const RateDetailItem = ({ title, description, rateValue, toolTip }: RateDetail): ReactElement => (
  <div className={styles.infoRow}>
    <div className={styles.infoDescription}>
      <div className={styles.title}>
        {title}
        {toolTip && (
          <ToolTip tip={toolTip} triggerHoverable>
            <IconExclamation />
          </ToolTip>
        )}
      </div>
      {description && <div className={styles.description}>{description}</div>}
    </div>
    <div className={styles.infoPrice}>
      <Heading alignment="rightAlign" size="small3x">
        {rateValue}
      </Heading>
    </div>
  </div>
);

export const AdditionalRatesDetails = ({
  sitterProfileServices,
}: RateDetailsProps): ReactElement => {
  const services = getServicesWithAdditionalRates(sitterProfileServices);
  const searchExperimentEnabled =
    useEppoStringAssignment(SFS_HOME_SEARCH_TEST_EPPO_ID) ===
    SFS_HOME_SEARCH_TEST_EPPO_ID_VALUES.ON;
  const optionsToShow = searchExperimentEnabled ? SERVICE_OPTIONS_V2 : SERVICE_OPTIONS;

  return (
    <div className={styles.root}>
      {services.map((service, index) => {
        const serviceData = findServiceById(service.service.serviceTypeId, optionsToShow);

        return (
          <div className={styles.ratesCard} key={index}>
            <div className={styles.content}>
              <div className={styles.icon}>
                <div className={styles.iconWrapper}>{serviceData?.icon}</div>
              </div>
              <div className={styles.info}>
                <div className={styles.infoRow}>
                  <div className={styles.infoDescription}>
                    <div className={styles.serviceTitle}>{serviceData?.title}</div>
                    <div className={styles.description}>{serviceData?.description}</div>
                  </div>
                  <div className={styles.infoPrice}>
                    <Heading alignment="rightAlign" size="small2x">
                      ${service.service.basePrice}
                    </Heading>
                    <span className={styles.timeUnit}>a {service.service.timeUnit}</span>
                  </div>
                </div>

                {service.additionalPetRate && (
                  <RateDetailItem
                    description={service.additionalPetRate.description}
                    rateValue={service.additionalPetRate.rateValue}
                    title={service.additionalPetRate.title}
                  />
                )}

                {service.puppySurcharge && (
                  <RateDetailItem
                    description={service.puppySurcharge.description}
                    rateValue={service.puppySurcharge.rateValue}
                    title={service.puppySurcharge.title}
                  />
                )}

                {/* TODO(WEB-2270): Revisit this */}
                {/* {service.seasonalSurcharge && (
                  <RateDetailItem
                    description={service.seasonalSurcharge.description}
                    rateValue={service.seasonalSurcharge.rateValue}
                    title={service.seasonalSurcharge.title}
                    toolTip={service.seasonalSurcharge.toolTip}
                  />
                )} */}

                {service.extStayDiscount && (
                  <RateDetailItem
                    description={service.extStayDiscount.description}
                    rateValue={service.extStayDiscount.rateValue}
                    title={service.extStayDiscount.title}
                  />
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
