import { gql } from '@apollo/client';

export const GET_SITTER_AVAILABILITY = gql`
  query sitterAvailability($id: Int!, $serviceId: Int!, $bookingType: String!) {
    sitterAvailability(id: $id, serviceId: $serviceId, bookingType: $bookingType) {
      unavailableDates
    }
  }
`;

export const GET_SITTER_PROFILE = gql`
  query sitterProfile($rewriteUrl: String!) {
    sitterProfile(rewriteUrl: $rewriteUrl) {
      id
      name
      card {
        area
        avatar
        firstName
        lastSeenForHumans
        rating
        repeatGuests
        responseRate
        sitterId
        totalReviews
        verified
      }
      details {
        headline
        description
        about {
          childrenAtHome {
            name
            value
          }
          firstAidCertificate {
            name
            value
          }
          hasPets {
            name
            value
          }
          hasPoliceCheckBadge {
            name
            value
          }
          responseWithinAnHour {
            name
            value
          }
        }
      }
      experience {
        experience {
          name
          value
        }
        firstName
        sitterId
      }
      home {
        firstName
        atHome {
          hasChildrenAtHome
          hasPetsAtHome
        }
        conditions {
          backyard
          property
          servicesAtHome {
            name
            value
          }
          sleep
        }
      }
      liked
      neighbourhood {
        area
        firstName
        lat
        lng
      }
      pets {
        firstName
        pets {
          ageMonth
          ageYears
          avatar
          breed
          id
          name
        }
      }
      petsAccepted {
        id
        label
        name
        description
      }
      photos {
        description
        id
        pathDownload
        pathThumbnail
        position
        src
      }
      policy {
        sitterId
        cancellationPolicy {
          description
          name
          value
        }
        lastMinuteBooking {
          name
          value
        }
        minimumStay {
          description
          name
          value
        }
      }
      questionsAndAnswers {
        informationToKnowBeforeBooking {
          name
          value
        }
        placeForWalking {
          name
          value
        }
        timeSpentWithPetDaily {
          name
          value
        }
      }
      sitterSettings {
        acceptedPetTypes
        acceptedServiceTypes
        sitterId
        providesRwbPerService
      }
      servicesOffered {
        firstName
        sitterId
        sitterProfileServices {
          additionalPetRate
          additionalServices {
            additionalServiceDiscount
            basePrice
            serviceTypeId
            timeUnit
          }
          basePrice
          extStayDiscount
          extStayTime
          groomingDiscount
          puppySurcharge
          seasonalSurcharge
          serviceTypeId
          timeUnit
          trainingDiscount
          walkingDiscount
        }
      }
      stickyComponent {
        baseRates {
          basePrice
          serviceTypeId
          timeUnit
        }
        firstName
        sitterId
      }
      stickyHeader {
        area
        avatar
        baseRates {
          basePrice
          serviceTypeId
          timeUnit
        }
        firstName
        liked
        rating
        sitterId
        totalReviews
      }
    }
  }
`;

export const GET_SITTER_REVIEWS = gql`
  query SitterReviews($sitterId: Int!, $limit: Int, $page: Int) {
    sitterReviews(id: $sitterId, limit: $limit, page: $page) {
      reviews {
        avatar
        createdAt
        description
        isVerifiedCustomer
        name
        rate
        serviceTypeId
        userId
      }
      pageInfo {
        offset
        size
        total
      }
    }
  }
`;
