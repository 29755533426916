import {
  AnimatedSpinner,
  Button,
  Form,
  IconPawPrint,
  IconPetSitting,
  LayoutVerticalSpacer,
  useDialogHeadingId,
} from '@madpaws/design-system';
import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';

import {
  PET_TYPE_IDS_TO_PET_TYPE_NAMES,
  SERVICE_TYPE_SLUGS_TO_SERVICE_TYPE_IDS,
} from '~/common/constants/app';
import { getServiceTypeIdFromSearchFilters } from '~/common/utils/search';
import {
  SFS_HOME_SEARCH_TEST_EPPO_ID,
  SFS_HOME_SEARCH_TEST_EPPO_ID_VALUES,
} from '~/components/Eppo/constants';
import { useEppoStringAssignment } from '~/components/Eppo/helpers';
import {
  SEARCH_FILTERS_FORM_ID,
  SERVICE_AND_BOOKING_TYPE_FORM_ID,
} from '~/components/SearchFiltersForm/constants';
import { BookingTypeField } from '~/components/SearchFiltersForm/ui/BookingType/BookingTypeField';
import { DateSelector } from '~/components/SearchFiltersForm/ui/DateSelector/DateSelector';
import { DialogFormField } from '~/components/SearchFiltersForm/ui/DialogFormField/DialogFormField';
import { ServiceTypeField } from '~/components/SearchFiltersForm/ui/ServiceType/ServiceTypeField';
import { YourPetsField } from '~/components/SearchFiltersForm/ui/YourPets/YourPetsField';
import {
  transformPetSelectionToString,
  transformServiceOptionsToString,
} from '~/components/SearchFiltersForm/utils';
import { trackEvent } from '~/components/analytics/analytics';
import {
  CLICKED_CONTACT_SITTER_EVENT_NAME,
  UPDATE_FILTER_PETS_EVENT_NAME,
  UPDATE_FILTER_SERVICE_EVENT_NAME,
} from '~/components/analytics/constants';
import { SERVICE_OPTIONS, SERVICE_OPTIONS_V2 } from '~/components/constants';

import styles from './ContactSitterForm.module.css';
import { ErrorBoundary } from './ErrorBoundary/ErrorBoundary';
import { getDefaultEventProperties } from '../../tracking/getDefaultEventProperties';
import { calculateNumberOfDays, countPets } from '../../utils';
import { checkAvailability, filterProvidedServiceTypes, getBookingTypeFromValues } from '../utils';

import type { ReactElement } from 'react';
import type { SearchFilters } from '~/common/types/search';
import type {
  SitterProfileSimpleBaseRates,
  SitterProfileSitterSettings,
} from '~/common/types/sitter';

type Props = {
  baseRates: SitterProfileSimpleBaseRates[];
  ctaId: string;
  firstName: string;
  isContactButtonLoading: boolean;
  setIsContactButtonLoading: (isContactButtonLoading: boolean) => void;
  setUnavailableDates: (dates: string[]) => void;
  sitterId: number;
  sitterSettings: SitterProfileSitterSettings;
  unavailableDates: string[];
};

const ContactSitterForm = ({
  baseRates,
  ctaId,
  firstName,
  sitterId,
  isContactButtonLoading,
  unavailableDates,
  setUnavailableDates,
  setIsContactButtonLoading,
  sitterSettings,
}: Props): ReactElement => {
  const contactWidgetRef = useRef<HTMLDivElement>(null);
  const { errors, values, handleSubmit } = useFormikContext<SearchFilters>();
  const {
    service: serviceType,
    chronology: { startDate, endDate },
  } = values;

  const dialogHeadingId = useDialogHeadingId();
  const searchExperimentEnabled =
    useEppoStringAssignment(SFS_HOME_SEARCH_TEST_EPPO_ID) ===
    SFS_HOME_SEARCH_TEST_EPPO_ID_VALUES.ON;

  const optionsToShow = searchExperimentEnabled ? SERVICE_OPTIONS_V2 : SERVICE_OPTIONS;
  const serviceTypeId = SERVICE_TYPE_SLUGS_TO_SERVICE_TYPE_IDS[values.service.type];
  const shouldShowBookingTypeField = sitterSettings.providesRwbPerService.includes(serviceTypeId);
  const serviceTypeIdFromSearchFilters = getServiceTypeIdFromSearchFilters(serviceType);

  const acceptedPetTypesNames = sitterSettings.acceptedPetTypes.map(
    (petTypeId) => PET_TYPE_IDS_TO_PET_TYPE_NAMES[petTypeId]
  );

  const { basePrice } =
    baseRates.find(({ serviceTypeId: id }) => id === serviceTypeIdFromSearchFilters) ??
    baseRates[0];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = (): void => {
    trackEvent(CLICKED_CONTACT_SITTER_EVENT_NAME, {
      booking_value: basePrice,
      num_time_units: calculateNumberOfDays(startDate, endDate),
      base_service_type: serviceTypeId,
      num_pets: countPets(values.petTypes),
      pet_types: values.petTypes,
      ...getDefaultEventProperties(sitterId),
    });
    handleSubmit();
  };

  const handleContactSitter = (): void => {
    onSubmit();
  };

  const isServiceTypeDisplayValueHasDescription = false;

  useEffect(() => {
    if (Object.keys(errors).length !== 0 && contactWidgetRef.current) {
      contactWidgetRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setIsContactButtonLoading(false);
    }
  }, [errors, setIsContactButtonLoading]);

  const triggerCheckAvailability = async (): Promise<void> => {
    const bookingType = getBookingTypeFromValues(values);

    const { unavailableDates: newUnavailableDates } = await checkAvailability(
      sitterId,
      serviceTypeId,
      bookingType
    );

    if (newUnavailableDates) {
      setUnavailableDates(newUnavailableDates);
    }
  };

  return (
    <div className={styles.root} ref={contactWidgetRef}>
      <Form
        hasHtml5Validation={false}
        hasTighterSpacing
        id={SEARCH_FILTERS_FORM_ID}
        isGridRowGapDisabled={false}
        label={{ id: dialogHeadingId }}
      >
        <LayoutVerticalSpacer>
          <ErrorBoundary fieldName="service">
            <DialogFormField
              displayValue={transformServiceOptionsToString(
                optionsToShow,
                values.service.type,
                values.bookingType,
                isServiceTypeDisplayValueHasDescription
              )}
              handleClose={triggerCheckAvailability}
              handleSubmit={(): void => trackEvent(UPDATE_FILTER_SERVICE_EVENT_NAME)}
              icon={<IconPetSitting />}
              isLabelVisuallyHidden
              label="Choose your service"
              placeholder="Choose service"
              visuallyHiddenLabel="To change the service type, click the field or press ENTER key to launch the service field in a dialog."
            >
              <Form id={SERVICE_AND_BOOKING_TYPE_FORM_ID} label={{ id: dialogHeadingId }}>
                <ServiceTypeField
                  options={filterProvidedServiceTypes(optionsToShow, sitterSettings)}
                />
                {shouldShowBookingTypeField && <BookingTypeField />}
              </Form>
            </DialogFormField>
          </ErrorBoundary>
          <ErrorBoundary fieldName="petTypes">
            <DialogFormField
              displayValue={transformPetSelectionToString(values.petTypes)}
              handleClose={triggerCheckAvailability}
              handleSubmit={(): void => trackEvent(UPDATE_FILTER_PETS_EVENT_NAME)}
              icon={<IconPawPrint />}
              isLabelVisuallyHidden
              label="Add your pets"
              placeholder="Add pets"
              visuallyHiddenLabel="To change the pet selection, click the field or press ENTER key to launch the pet field in a dialog."
            >
              <YourPetsField acceptedPetTypesNames={acceptedPetTypesNames} />
            </DialogFormField>
          </ErrorBoundary>
          <ErrorBoundary fieldName="chronology">
            <DateSelector
              dateFormat="fancy"
              disabledDates={unavailableDates}
              isCalendarIconHidden={false}
              isLabelVisuallyHidden
            />
          </ErrorBoundary>
        </LayoutVerticalSpacer>
        <div id={ctaId}>
          <Button
            iconLeading={isContactButtonLoading ? <AnimatedSpinner size="medium" /> : undefined}
            isDisabled={isContactButtonLoading}
            isFullBleed
            label={firstName ? `Contact ${firstName}` : `Contact sitter`}
            onClick={handleContactSitter}
          />
        </div>
        <div className={styles.notice}>You won&apos;t be asked to pay yet.</div>
      </Form>
    </div>
  );
};

export { ContactSitterForm };
