import {
  Divider,
  LayoutGridContainer,
  LayoutGridItem,
  LayoutVerticalSpacer,
  useMediumFromMediaQuery,
} from '@madpaws/design-system';
import React, { useState, useEffect } from 'react';

import { getServiceTypeIdFromSearchFilters } from '~/common/utils/search';
import { trackEvent } from '~/components/analytics/analytics';
import { VIEWED_SITTER_PROFILE_EVENT_NAME } from '~/components/analytics/constants';
import { SERVICE_TYPES_IDS_SUPPORTING_AREA_10 } from '~/utils/area10ProgramaticContent/constants';

import { ContactSitterWidget } from './ContactSitterWidget/ContactSitterWidget';
import { CustomerReviews } from './CustomerReviews/CustomerReviews';
import { SitterNeighbourhood } from './Neighbourhood/SitterNeighbourhood';
import { PageHead } from './PageHead/PageHead';
import { PetsAccepted } from './PetsAccepted/PetsAccepted';
import { Policies } from './Policies/Policies';
import { QnA } from './QnA/QnA';
import { ReportProfile } from './ReportProfile/ReportProfile';
import { ServicesOffered } from './ServicesOffered/ServicesOffered';
import { SitterCard } from './SitterCard/SitterCard';
import { SitterDetailsCard } from './SitterDetailsCard/SitterDetailsCard';
import { SitterHome } from './SitterHome/SitterHome';
import { SitterPets } from './SitterPets/SitterPets';
import { PhotoGalleryDesktop } from './SitterPhotoGallery/PhotoGalleryDesktop/PhotoGalleryDesktop';
import { PhotoGalleryMobile } from './SitterPhotoGallery/PhotoGalleryMobile/PhotoGalleryMobile';
import styles from './SitterProfile.module.css';
import { SitterProfileDesktopStickyHeader } from './SitterProfileDesktopStickyHeader/SitterProfileDesktopStickyHeader';
import { SitterProfileMobileStickyFooter } from './SitterProfileMobileStickyFooter/SitterProfileMobileStickyFooter';
import { SitterSeoProgrammaticContent } from './SitterSeoProgrammaticContent/SitterSeoProgrammaticContent';
import { SitterProfilePageProvider } from './components/SitterProfileProvider';
import { getAdditionalEventProperties } from './tracking/getAdditionalEventProperties';
import { getDefaultEventProperties } from './tracking/getDefaultEventProperties';
import { getPetTypesAcceptedProperties } from './tracking/getPetTypesAcceptedProperties';

import type { NextPage } from 'next';
import type { SearchFilters } from '~/common/types/search';
import type { SitterProfile as SitterProfileProps } from '~/common/types/sitter';

type Props = {
  canonicalUrl: string | null;
  extraQueryParameters: string;
  locationSlug: string;
  searchFilters: SearchFilters;
  sitterProfile: SitterProfileProps;
  sitterProfileSlug: string;
};

const SitterProfile: NextPage<Props> = ({
  extraQueryParameters,
  locationSlug,
  sitterProfile,
  searchFilters,
  canonicalUrl,
  sitterProfileSlug,
}) => {
  const [isSitterCardSticky, setIsSitterCardSticky] = useState(false);
  const CONTACT_SITTER_WIDGET_ID = 'contact-sitter-widget';
  const CONTACT_SITTER_WIDGET_BUTTON_ID = 'contact-sitter-widget-button';
  const isMediumFromViewport = useMediumFromMediaQuery();
  const {
    id,
    name,
    card,
    liked,
    stickyComponent,
    petsAccepted,
    neighbourhood,
    details,
    experience,
    home,
    policy,
    servicesOffered,
    questionsAndAnswers,
    photos,
    pets,
    stickyHeader,
    sitterSettings,
  } = sitterProfile;
  const { baseRates } = stickyComponent;
  const [isSitterLiked, setIsSitterLiked] = useState(liked);
  const [isContactButtonLoading, setIsContactButtonLoading] = useState(false);

  const shouldRenderArea10SeoContent = SERVICE_TYPES_IDS_SUPPORTING_AREA_10.find((serviceTypeId) =>
    sitterProfile.sitterSettings.acceptedServiceTypes.includes(serviceTypeId)
  );
  const serviceTypeIdFromSearchFilters = getServiceTypeIdFromSearchFilters(searchFilters.service);
  const { basePrice } =
    baseRates.find(({ serviceTypeId }) => serviceTypeId === serviceTypeIdFromSearchFilters) ??
    baseRates[0];

  useEffect(() => {
    const handleScroll = (): void => {
      // Find the contact sitter widget component by its ID
      const component = document.getElementById(CONTACT_SITTER_WIDGET_ID);
      if (!component) {
        return;
      }

      // Get the bottom position of the component relative to the viewport
      const { bottom } = component.getBoundingClientRect();
      // Check if the component is past the viewport
      const isPastComponent = bottom < 0;
      // Set the sticky state if the component is past the viewport
      setIsSitterCardSticky(isPastComponent);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    trackEvent(VIEWED_SITTER_PROFILE_EVENT_NAME, {
      service_rate: basePrice,
      version: 'new',
      viewed_on_date: new Date(),
      ...getDefaultEventProperties(id),
      ...getAdditionalEventProperties(
        policy,
        searchFilters,
        details,
        home,
        experience,
        card,
        sitterSettings,
        pets
      ),
      ...getPetTypesAcceptedProperties(petsAccepted),
    });
    // only run once after initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageHead
        canonicalUrl={canonicalUrl}
        searchFilters={searchFilters}
        sitterProfile={sitterProfile}
      />

      <SitterProfilePageProvider
        isSitterLiked={isSitterLiked}
        locationSlug={locationSlug}
        profile={sitterProfile}
        setIsSitterLiked={setIsSitterLiked}
        sitterProfileSlug={sitterProfileSlug}
      >
        <div className={styles.container}>
          {isMediumFromViewport && isSitterCardSticky && (
            <SitterProfileDesktopStickyHeader
              ctaId={CONTACT_SITTER_WIDGET_BUTTON_ID}
              isContactButtonLoading={isContactButtonLoading}
              searchFilters={searchFilters}
              setIsContactButtonLoading={setIsContactButtonLoading}
              stickyHeader={stickyHeader}
            />
          )}

          {!isMediumFromViewport && (
            <LayoutVerticalSpacer space="large1x">
              <PhotoGalleryMobile canonicalUrl={canonicalUrl} photos={photos} sitterCard={card} />
            </LayoutVerticalSpacer>
          )}

          <div className={styles.wrapper}>
            <LayoutGridContainer>
              <LayoutGridItem placement={{ small: [1, 13], medium: [1, 13], large: [2, 12] }}>
                <LayoutVerticalSpacer space="large1x">
                  <SitterCard
                    canonicalUrl={canonicalUrl}
                    isSitterLiked={isSitterLiked}
                    setIsSitterLiked={setIsSitterLiked}
                    sitterCard={card}
                  />
                </LayoutVerticalSpacer>

                {isMediumFromViewport ? (
                  <PhotoGalleryDesktop photos={photos} sitterId={id} />
                ) : (
                  <></>
                )}
              </LayoutGridItem>

              <LayoutGridItem placement={{ small: [1, 13], medium: [1, 8], large: [2, 8] }}>
                <SitterDetailsCard details={details} experience={experience} />

                {!isMediumFromViewport ? (
                  <LayoutVerticalSpacer space="large1x">
                    <ServicesOffered servicesOffered={servicesOffered} />
                  </LayoutVerticalSpacer>
                ) : (
                  <></>
                )}

                <LayoutVerticalSpacer space={isMediumFromViewport ? 'large2x' : 'large1x'}>
                  <PetsAccepted petsAccepted={petsAccepted} />
                </LayoutVerticalSpacer>
                <LayoutVerticalSpacer space={isMediumFromViewport ? 'large2x' : 'large1x'}>
                  <SitterHome home={home} />
                </LayoutVerticalSpacer>
                <LayoutVerticalSpacer space={isMediumFromViewport ? 'large2x' : 'large1x'}>
                  <SitterPets sitterPets={pets} />
                </LayoutVerticalSpacer>
              </LayoutGridItem>

              {isMediumFromViewport ? (
                <LayoutGridItem placement={{ small: [1, 13], medium: [8, 13], large: [8, 12] }}>
                  <LayoutVerticalSpacer space="large2x">
                    <ContactSitterWidget
                      ctaId={CONTACT_SITTER_WIDGET_BUTTON_ID}
                      extraQueryParameters={extraQueryParameters}
                      id={CONTACT_SITTER_WIDGET_ID}
                      isContactButtonLoading={isContactButtonLoading}
                      searchFilters={searchFilters}
                      setIsContactButtonLoading={setIsContactButtonLoading}
                      sitterProfileSlug={sitterProfileSlug}
                      sitterSettings={sitterSettings}
                      stickyComponent={stickyComponent}
                    />
                  </LayoutVerticalSpacer>

                  <ServicesOffered servicesOffered={servicesOffered} />
                </LayoutGridItem>
              ) : (
                <></>
              )}
              <LayoutGridItem placement={{ small: [1, 13], medium: [1, 13], large: [2, 12] }}>
                <LayoutVerticalSpacer space={isMediumFromViewport ? 'large3x' : 'large1x'}>
                  <Divider />
                </LayoutVerticalSpacer>
                <LayoutVerticalSpacer space={isMediumFromViewport ? 'large3x' : 'large1x'}>
                  <SitterNeighbourhood neighbourhood={neighbourhood} />
                </LayoutVerticalSpacer>
                <LayoutVerticalSpacer space={isMediumFromViewport ? 'large3x' : 'large1x'}>
                  <CustomerReviews canonicalUrl={canonicalUrl} sitterCard={card} />
                </LayoutVerticalSpacer>
                <LayoutVerticalSpacer space={isMediumFromViewport ? 'large3x' : 'large1x'}>
                  <QnA questionsAndAnswers={questionsAndAnswers} />
                </LayoutVerticalSpacer>
                <LayoutVerticalSpacer space={isMediumFromViewport ? 'large3x' : 'large1x'}>
                  <Policies policies={policy} />
                </LayoutVerticalSpacer>
                <LayoutVerticalSpacer space={isMediumFromViewport ? 'large3x' : 'large1x'}>
                  <ReportProfile id={id} name={name} />
                </LayoutVerticalSpacer>
              </LayoutGridItem>
              {shouldRenderArea10SeoContent ? (
                <SitterSeoProgrammaticContent area={card.area} articleId={sitterProfileSlug} />
              ) : (
                <></>
              )}
            </LayoutGridContainer>
          </div>

          {!isMediumFromViewport && (
            <SitterProfileMobileStickyFooter
              searchFilters={searchFilters}
              sitterProfileSlug={sitterProfileSlug}
              stickyFooter={stickyComponent}
            />
          )}
        </div>
      </SitterProfilePageProvider>
    </>
  );
};

export { SitterProfile };
