import { IconButton, IconHeartFilled, IconHeartOutlined } from '@madpaws/design-system';
import classNames from 'classnames';
import React, { useEffect } from 'react';

import { useUserLoggedInContext } from '~/components/UserAuthProvider/UserAuthProvider';
import { trackEvent } from '~/components/analytics/analytics';
import {
  SITTER_FAVOURITED_EVENT_NAME,
  SITTER_UNFAVOURITE_EVENT_NAME,
  SitterFavouriteSourcesEnum,
  SITTER_FAVOURITED_CLICK,
  SITTER_UNFAVOURITE_CLICK,
} from '~/components/analytics/constants';
import { SitterApi } from '~/services/sitter/SitterApi';
import { getLocalStorage, removeLocalStorage } from '~/utils/localStorage';

import styles from './FavoriteButton.module.css';
import { LoginRequiredDialog } from './LoginRequiredDialog';
import { FAVORITE_SITTER_ID_LOCAL_STORAGE_KEY } from './constants';
import { getDefaultEventProperties } from '../../tracking/getDefaultEventProperties';
import { useSitterProfilePageContext } from '../../utils';

import type { ReactElement } from 'react';

export const FavoriteButton = (): ReactElement => {
  const {
    profile,
    isSitterLiked,
    locationSlug,
    setIsSitterLiked,
    sitterProfileSlug,
  } = useSitterProfilePageContext();
  const { isUserLoggedIn } = useUserLoggedInContext();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [isAnimating, setIsAnimating] = React.useState(false);

  const sitterProfileURL = `petsitter/${locationSlug}/${sitterProfileSlug}`;

  const handleUpdateFavoriteSitter = async (
    sitterIdNumber: number,
    isSitterFavorite: boolean
  ): Promise<void> => {
    const response = await SitterApi.updateFavoriteSitter(sitterIdNumber, isSitterFavorite);
    const clickEventName = isSitterFavorite ? SITTER_FAVOURITED_CLICK : SITTER_UNFAVOURITE_CLICK;

    trackEvent(clickEventName, {
      sitterId: sitterIdNumber,
      source: SitterFavouriteSourcesEnum.SITTER_PROFILE,
      sitter_name: profile?.name,
      sitter_photo: profile?.card.avatar,
      sitter_profile_url: sitterProfileURL,
      ...getDefaultEventProperties(sitterIdNumber),
    });

    if (response) {
      const eventName = isSitterFavorite
        ? SITTER_UNFAVOURITE_EVENT_NAME
        : SITTER_FAVOURITED_EVENT_NAME;

      trackEvent(eventName, { ...getDefaultEventProperties(sitterIdNumber) });
      return;
    }

    // Reset the state to the previous value
    setIsSitterLiked(!isSitterFavorite);
  };

  useEffect(() => {
    const favoriteSitterId = getLocalStorage(FAVORITE_SITTER_ID_LOCAL_STORAGE_KEY, 0);
    // In case user is logged in
    // and favoriteSitterId in local storage is the same as the current sitterId
    // but sitter is not liked, then like the sitter
    if (isUserLoggedIn && favoriteSitterId === profile?.id && isSitterLiked === false) {
      setIsSitterLiked(true);

      handleUpdateFavoriteSitter(profile?.id as number, true);

      removeLocalStorage(FAVORITE_SITTER_ID_LOCAL_STORAGE_KEY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggedIn]);

  const onClick = async (): Promise<void> => {
    if (!isUserLoggedIn) {
      setIsDialogOpen(true);
      return;
    }

    setIsSitterLiked(!isSitterLiked);
    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
    }, 500);

    handleUpdateFavoriteSitter(profile?.id as number, !isSitterLiked);
  };

  return (
    <div
      className={classNames({
        [styles.isLikedAnimation]: isAnimating && isSitterLiked,
        [styles.isLiked]: isSitterLiked,
      })}
    >
      <IconButton label="Favorite" onClick={onClick} variant="secondary">
        {isSitterLiked ? <IconHeartFilled /> : <IconHeartOutlined />}
      </IconButton>
      <LoginRequiredDialog
        isOpen={isDialogOpen}
        onDialogClose={(): void => setIsDialogOpen(false)}
        sitterId={profile?.id as number}
      />
    </div>
  );
};
