import { createContext } from 'react';

import type { ReactElement } from 'react';
import type { SitterProfile as SitterProfileProps } from '~/common/types/sitter';

export type SitterProfilePageContextProps = {
  isSitterLiked: boolean;
  locationSlug: string;
  profile: SitterProfileProps | null;
  setIsSitterLiked: (isSitterLiked: boolean) => void;
  sitterProfileSlug: string;
};

export const SitterProfilePageContext = createContext<SitterProfilePageContextProps>({
  isSitterLiked: false,
  locationSlug: '',
  profile: null,
  setIsSitterLiked: () => {},
  sitterProfileSlug: '',
});

type Props = {
  children: ReactElement | ReactElement[];
  isSitterLiked: boolean;
  locationSlug: string;
  profile: SitterProfileProps;
  setIsSitterLiked: (isSitterLiked: boolean) => void;
  sitterProfileSlug: string;
};

export const SitterProfilePageProvider = ({
  children,
  isSitterLiked,
  locationSlug,
  profile,
  setIsSitterLiked,
  sitterProfileSlug,
}: Props): ReactElement => (
  <SitterProfilePageContext.Provider
    value={{ isSitterLiked, locationSlug, profile, setIsSitterLiked, sitterProfileSlug }}
  >
    {children}
  </SitterProfilePageContext.Provider>
);
