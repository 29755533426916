import { Heading } from '@madpaws/design-system';
import classNames from 'classnames';
import { useFormikContext } from 'formik';

import { getServiceTypeIdFromSearchFilters } from '~/common/utils/search';
import {
  SFS_HOME_SEARCH_TEST_EPPO_ID,
  SFS_HOME_SEARCH_TEST_EPPO_ID_VALUES,
} from '~/components/Eppo/constants';
import { useEppoStringAssignment } from '~/components/Eppo/helpers';
import { SERVICE_OPTIONS_V2, SERVICE_OPTIONS } from '~/components/constants';

import styles from './ContactSitterServicePrice.module.css';
import { getServiceInfoFromOptions } from './utils';

import type { ReactElement } from 'react';
import type { SearchFilters } from '~/common/types/search';
import type { SitterProfileSimpleBaseRates } from '~/common/types/sitter';

type ContactSitterWidgetProps = {
  baseRates: SitterProfileSimpleBaseRates[];
};

export const ContactSitterServicePrice = ({
  baseRates,
}: ContactSitterWidgetProps): ReactElement => {
  const { values } = useFormikContext<SearchFilters>();
  const searchExperimentEnabled =
    useEppoStringAssignment(SFS_HOME_SEARCH_TEST_EPPO_ID) ===
    SFS_HOME_SEARCH_TEST_EPPO_ID_VALUES.ON;

  const optionsToShow = searchExperimentEnabled ? SERVICE_OPTIONS_V2 : SERVICE_OPTIONS;
  const { service: serviceType } = values;
  const serviceTypeIdFromSearchFilters = getServiceTypeIdFromSearchFilters(serviceType);

  const { basePrice, timeUnit, serviceTypeId } =
    baseRates.find(({ serviceTypeId: id }) => id === serviceTypeIdFromSearchFilters) ??
    baseRates[0];

  const [serviceName, serviceDescription] = getServiceInfoFromOptions(
    serviceTypeId,
    serviceType.type,
    optionsToShow
  );

  return (
    <div
      className={classNames(styles.header, {
        [styles.noDescription]: !serviceDescription,
      })}
    >
      <div>
        <Heading size="small1x">{serviceName}</Heading>
        <span className={styles.description}>{serviceDescription}</span>
      </div>

      <div className={styles.priceInformation}>
        <span>from</span>
        <strong className={styles.basePrice}>${basePrice}</strong>
        <span>a {timeUnit}</span>
      </div>
    </div>
  );
};
