import {
  Divider,
  Heading,
  IconShieldCheckFilled,
  IconStar,
  ToolTip,
  useMediumFromMediaQuery,
} from '@madpaws/design-system';
import React from 'react';

import styles from './SitterCard.module.css';
import { FavoriteButton } from '../components/FavoriteButton/FavoriteButton';
import { ReviewsLink } from '../components/ReviewsLink/ReviewsLink';
import { ShareButton } from '../components/ShareButton/ShareButton';

import type { ReactElement } from 'react';
import type { SitterProfileCard } from '~/common/types/sitter';

export type Props = {
  canonicalUrl: string | null;
  isSitterLiked: boolean;
  setIsSitterLiked: (isSitterLiked: boolean) => void;
  sitterCard: SitterProfileCard;
};

export const SitterCard = ({ canonicalUrl, sitterCard }: Props): ReactElement => {
  const isMediumFromViewport = useMediumFromMediaQuery();

  const {
    area,
    firstName,
    lastSeenForHumans,
    rating,
    repeatGuests,
    responseRate,
    sitterId,
    totalReviews,
    verified,
  } = sitterCard;

  const toolTip = 'We verify the identity of all Mad Paws sitters for your safety.';

  return (
    <div className={styles.root}>
      <div className={styles.main}>
        <div className={styles.data}>
          <div className={styles.firstRow}>
            <Heading fontFamily="heading" size={isMediumFromViewport ? 'medium' : 'small1x'}>
              {firstName}
            </Heading>
            {verified && (
              <ToolTip tip={toolTip} triggerHoverable>
                <IconShieldCheckFilled size={isMediumFromViewport ? 'medium' : 'small'} />
              </ToolTip>
            )}
            {repeatGuests > 0 && (
              <div className={styles.repeat}>
                {repeatGuests === 1
                  ? `${repeatGuests} repeat guest`
                  : `${repeatGuests} repeat guests`}
              </div>
            )}
          </div>

          <div className={styles.secondRow}>
            <div className={styles.rating}>
              <IconStar /> {rating.toFixed(1)}
            </div>
            <ReviewsLink totalReviews={totalReviews} />
            <div className={styles.verticalDivider} />
            <div className={styles.area}>{area}</div>
          </div>

          <div className={styles.thirdRow}>
            <div>{responseRate}% response rate</div>
            <div>Last seen {lastSeenForHumans}</div>
          </div>
        </div>

        {isMediumFromViewport && (
          <div className={styles.actions}>
            <ShareButton canonicalUrl={canonicalUrl} sitterCard={sitterCard} sitterId={sitterId} />
            <FavoriteButton />
          </div>
        )}
      </div>

      {!isMediumFromViewport && <Divider />}
    </div>
  );
};
