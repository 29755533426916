import {
  Divider,
  Heading,
  IconBaby,
  IconCheck,
  IconClock,
  IconCross,
  IconPaw,
  IconShieldStar,
  LayoutVerticalSpacer,
  useMediumFromMediaQuery,
} from '@madpaws/design-system';
import React, { useMemo } from 'react';

import { trackEvent } from '~/components/analytics/analytics';
import { CLICKED_EXPAND_SITTER_BIO_EVENT_NAME } from '~/components/analytics/constants';
import { QuoteText } from '~/tab-manager/ui';

import styles from './SitterDetailsCard.module.css';
import { SitterTraits } from './constants';
import { getDefaultEventProperties } from '../tracking/getDefaultEventProperties';

import type { ReactElement } from 'react';
import type { BooleanProp, SitterAbout, SitterProfileDetails } from '~/common/types/sitter';

type Props = {
  details: SitterProfileDetails;
  experience: {
    experience: BooleanProp[];
    firstName: string;
    sitterId: number;
  };
};

type ExperienceListProps = {
  name: string;
  value: boolean;
};

const traitIconMapping = {
  [SitterTraits.CHILDREN_AT_HOME]: <IconBaby />,
  [SitterTraits.FIRST_AID_CERTIFICATE]: <IconCross />,
  [SitterTraits.HAS_PETS]: <IconPaw />,
  [SitterTraits.HAS_POLICE_CHECK_BADGE]: <IconShieldStar />,
  [SitterTraits.RESPONSE_WITH_IN_AN_HOUR]: <IconClock />,
};

export const SitterDetailsCard = ({
  details: { about, headline, description },
  experience: { experience, firstName, sitterId },
}: Props): ReactElement => {
  const isMediumFromViewport = useMediumFromMediaQuery();

  const getIconForTrait = (trait: string): ReactElement => traitIconMapping[trait];
  const filteredExperienceData = useMemo(() => experience?.filter((item) => item.value) ?? [], [
    experience,
  ]);

  const renderTraits = (aboutData: SitterAbout): ReactElement | null => {
    const filteredAboutData = Object.entries(aboutData).filter(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([_, traitData]) => traitData?.value
    );

    if (!filteredAboutData.length) {
      return null;
    }

    return (
      <div className={`${styles.wrapper} ${styles.traitsWrapper}`}>
        {filteredAboutData.map(
          ([trait, traitData]) =>
            traitData.value && (
              <span className={styles.listItem} key={trait}>
                {getIconForTrait(trait)}
                <span>{traitData.name}</span>
              </span>
            )
        )}
      </div>
    );
  };

  const onQuoteTextExpanded = (): void => {
    trackEvent(CLICKED_EXPAND_SITTER_BIO_EVENT_NAME, {
      ...getDefaultEventProperties(sitterId),
    });
  };

  const renderExperience = (experienceData: ExperienceListProps[]): ReactElement | null => {
    const shouldRenderExperience = experienceData.length > 0;
    if (!shouldRenderExperience) {
      return null;
    }

    return (
      <div>
        <div className={styles.experienceHeading}>{firstName}&apos;s experience</div>
        <div className={`${styles.wrapper} ${styles.experienceWrapper}`}>
          {experienceData.map((item, index) => (
            <span className={styles.listItem} key={index}>
              <IconCheck />
              <span>{item.name}</span>
            </span>
          ))}
        </div>
      </div>
    );
  };

  return (
    <LayoutVerticalSpacer space={isMediumFromViewport ? 'large2x' : 'large1x'}>
      <div className={styles.root}>
        <Heading size={isMediumFromViewport ? 'small2x' : 'small3x'}>{headline}</Heading>
        {renderTraits(about)}
        <div className={styles.descriptionWrapper}>
          <QuoteText
            onQuoteTextExpanded={onQuoteTextExpanded}
            tabElement={{
              quoteTextItems: description.split('\n'),
              align: 'left',
              title: `About ${firstName}`,
            }}
            truncationPoint={isMediumFromViewport ? 280 : 125}
          />
        </div>
        {renderExperience(filteredExperienceData)}
      </div>
      <Divider />
    </LayoutVerticalSpacer>
  );
};
