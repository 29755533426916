import { SERVICE_OPTIONS } from '~/components/constants';

export const getServiceInfoFromOptions = (
  serviceTypeId: number,
  serviceType?: string,
  options = SERVICE_OPTIONS
): [string, string] => {
  const service =
    options.find((option) => option.value === serviceType) ||
    options.find((option) => option.serviceTypeId === serviceTypeId) ||
    options[0];

  const serviceName = service.label;

  let serviceDescription = '';

  if (service.description) {
    serviceDescription = service.description;
  }

  return [serviceName, serviceDescription];
};
